.quantity {
	grid-area: quantity;
	justify-self: center;
	display: grid;
	justify-items: center;
	grid-template-columns: repeat(3, 1fr);
	column-gap: 0.5rem;
}

.quantity span {
	font-size: 3rem;
	line-height: 1.5;
	width: 5rem;
	text-align: center;
}

.quantity__btn {
	border: none;
	background: transparent;
	color: currentColor;
	cursor: pointer;
	padding: 0.5rem;
}
