.item {
	display: grid;
	row-gap: 2rem;
	max-width: 30rem;
	max-height: 45rem;
	height: 100%;
	background: var(--light-black);
	margin: 2rem;
	padding: 2rem 4rem;
	border-radius: 1.5rem;
	position: relative;
	font-size: 1.5rem;
	filter: drop-shadow(0.2rem 0.2rem 0.4rem var(--light-black));
	-webkit-filter: drop-shadow(0.2rem 0.2rem 0.4rem var(--light-black));
}

.item__img {
	width: 90%;
	height: 15rem;
	position: absolute;
	top: 0%;
	left: 50%;
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
}

.item__img img {
	width: 100%;
	height: 100%;
}

.item__text {
	text-align: center;
	margin-top: 5rem;
}

.name {
	font-size: 2.2rem;
	font-family: 'Fira Sans';
	border: none;
	border-bottom: 0.1rem solid var(--pale-yellow);
	padding: 1rem;
}

.description {
	font-size: 1.5rem;
	font-family: 'Montserrat';
	padding: 1rem;
	text-overflow: ellipsis;
	overflow-y: hidden;
	white-space: normal;
	line-height: 1.5;
	height: 10rem;

	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
}

.item .item__btn {
	padding: 1rem 2.5rem;
	margin: 0 auto;
	position: absolute;
	left: 50%;
	bottom: 0;
	transform: translate(-50%, 50%);
	-webkit-transform: translate(-50%, 50%);
	-moz-transform: translate(-50%, 50%);
	-ms-transform: translate(-50%, 50%);
	-o-transform: translate(-50%, 50%);
}

.item__info {
	display: grid;
	grid-template-areas: 'price slices' 'quantity quantity';
	align-items: center;
	align-content: center;
	column-gap: 2rem;
	margin: 0 0 2rem;
	font-size: 2rem;
}

.price {
	grid-area: price;
}

.slices {
	grid-area: slices;
	display: flex;
	justify-content: space-evenly;
}
