@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fira+Sans:wght@700;800&display=swap);
/* Montserrat font */

/* Fira Sans font */

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	outline: none;
}

:root {
	font-size: 62.5%;
	/* font-sizes */

	--h1: clamp(4.2rem, 10vw, 6.5rem);

	--h2: clamp(2.5rem, 10vw, 5.5rem);

	--h3: clamp(2rem, 10vw, 3rem);

	--h4: clamp(0.8rem, 10vw, 2.5rem);

	--small-text: clamp(0.8rem, 10vw, 2rem);

	--tiny-text: clamp(0.5rem, 10vw, 1.4rem);

	--white: #ffffff;
	--black: #292929;
	--light-black: #3a373e;
	--light-grey: #cecece;
	--light-red-orange: #e2842e;
	--dark-red-orange: #bb5320;
	--pale-yellow: #f3d18e;

	--red-orange-gradient: linear-gradient(
		-90deg,
		rgba(226, 132, 46, 1) 10%,
		rgba(190, 86, 33, 1) 60%,
		rgba(187, 83, 32, 1) 95%
	);
}

body {
	font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: #3a373e;
	background: var(--light-black);
	color: #ffffff;
	color: var(--white);
}

.app_app__2Mry- {
	max-width: 2200px;
	margin: auto;
}

a {
	text-decoration: none;
}

.navbar_header__1L2QD {
	display: grid;
	align-items: center;
	justify-content: space-between;
	grid-template-columns: 0.5fr 1fr 0.2fr;
	grid-template-areas: 'logo links cart';
	line-height: 9rem;

	background: var(--light-black);
	font-size: var(--small-text);
	padding: 2rem 7rem;
}

.navbar_header__logo__p0GA9 {
	grid-area: logo;
	font-weight: 700;
	font-size: 3.5rem;
	color: var(--white);
	display: flex;
	align-items: center;
}

.navbar_header__logo__p0GA9 img {
	width: 10rem;
	transform: scale(0.7);
	-webkit-transform: scale(0.7);
	-moz-transform: scale(0.7);
	-ms-transform: scale(0.7);
	-o-transform: scale(0.7);
}

.navbar_header__links__3zwxo {
	grid-area: links;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	width: 100%;
}

.navbar_link__3J2sA {
	color: var(--white);
	filter: drop-shadow(0.5rem 0.5rem 0.5rem var(--black));
	-webkit-filter: drop-shadow(0.5rem 0.5rem 0.5rem var(--black));
	position: relative;
	cursor: pointer;
}

.navbar_link__3J2sA::after {
	content: '';
	display: block;
	position: absolute;
	bottom: 25%;
	left: 51%;
	right: 51%;
	height: 0.2rem;
	background: currentColor;
	transition: all 0.3s 0.09s ease;
	-webkit-transition: all 0.3s 0.09s ease;
	-moz-transition: all 0.3s 0.09s ease;
	-ms-transition: all 0.3s 0.09s ease;
	-o-transition: all 0.3s 0.09s ease;
}

.navbar_link__3J2sA:hover::after {
	left: 0;
	right: 0;
}

.navbar_header__cart__C8ELH {
	grid-area: cart;
	position: relative;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.navbar_nav__hamburger__3JnGH {
	display: none;
	place-items: center;
	border: none;
	background: transparent;
	font-size: 3rem;
	color: currentColor;
	cursor: pointer;
}

.navbar_cart__btn__K9oxZ {
	display: grid;
	place-items: center;
	position: relative;
	padding: 2rem;
	cursor: pointer;
	border: none;
	background: transparent;
	color: var(--light-red-orange);
	filter: drop-shadow(0.5rem 0.5rem 0.5rem var(--black));
	-webkit-filter: drop-shadow(0.5rem 0.5rem 0.5rem var(--black));
	font-size: 4rem;
}

.navbar_cart__counter__2-H7D {
	display: grid;
	place-items: center;
	position: absolute;
	bottom: 15%;
	right: 15%;
	width: 2.5rem;
	height: 2.5rem;
	background: var(--white);
	color: currentColor;
	font-size: 2rem;
	font-weight: 600;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
}

@media (max-width: 1200px) {
	.navbar_header__1L2QD {
		grid-template-columns: 0.5fr 1fr;
		grid-template-areas: 'logo cart';
	}

	.navbar_header__links__3zwxo {
		display: none;
	}

	.navbar_mobile__links__1UQ0a {
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		align-items: center;
		margin: 0 auto;
		z-index: 3;
		background: var(--black);
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transition: all 0.2s linear;
		-webkit-transition: all 0.2s linear;
		-moz-transition: all 0.2s linear;
		-ms-transition: all 0.2s linear;
		-o-transition: all 0.2s linear;
		animation: navbar_toggle__menu__2h8VP 0.4s linear;
		-webkit-animation: navbar_toggle__menu__2h8VP 0.4s linear;
	}

	.navbar_header__cart__C8ELH {
		justify-content: flex-end;
		align-items: baseline;
	}

	.navbar_nav__hamburger__3JnGH {
		display: grid;
	}

	.navbar_nav__close__2FN-k {
		display: grid;
		position: absolute;
		top: 5%;
		right: 5%;
	}
}

@media (max-width: 768px) {
	.navbar_header__1L2QD {
		padding: 2rem;
	}

	.navbar_header__logo__p0GA9 span {
		display: none;
	}
}

@-webkit-keyframes navbar_toggle__menu__2h8VP {
	0% {
		opacity: 0;
		top: 0;
	}

	50% {
		opacity: 0;
		top: 100%;
	}

	100% {
		opacity: 1;
		width: 100%;
		height: 100%;
	}
}

@keyframes navbar_toggle__menu__2h8VP {
	0% {
		opacity: 0;
		top: 0;
	}

	50% {
		opacity: 0;
		top: 100%;
	}

	100% {
		opacity: 1;
		width: 100%;
		height: 100%;
	}
}

.menu-item_item__3HcUS {
	display: grid;
	grid-row-gap: 2rem;
	row-gap: 2rem;
	max-width: 30rem;
	max-height: 45rem;
	height: 100%;
	background: var(--light-black);
	margin: 2rem;
	padding: 2rem 4rem;
	border-radius: 1.5rem;
	position: relative;
	font-size: 1.5rem;
	filter: drop-shadow(0.2rem 0.2rem 0.4rem var(--light-black));
	-webkit-filter: drop-shadow(0.2rem 0.2rem 0.4rem var(--light-black));
}

.menu-item_item__img__2RSF0 {
	width: 90%;
	height: 15rem;
	position: absolute;
	top: 0%;
	left: 50%;
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
}

.menu-item_item__img__2RSF0 img {
	width: 100%;
	height: 100%;
}

.menu-item_item__text__AqRsy {
	text-align: center;
	margin-top: 5rem;
}

.menu-item_name__2fqMk {
	font-size: 2.2rem;
	font-family: 'Fira Sans';
	border: none;
	border-bottom: 0.1rem solid var(--pale-yellow);
	padding: 1rem;
}

.menu-item_description__1EpFA {
	font-size: 1.5rem;
	font-family: 'Montserrat';
	padding: 1rem;
	text-overflow: ellipsis;
	overflow-y: hidden;
	white-space: normal;
	line-height: 1.5;
	height: 10rem;

	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
}

.menu-item_item__3HcUS .menu-item_item__btn__12NtZ {
	padding: 1rem 2.5rem;
	margin: 0 auto;
	position: absolute;
	left: 50%;
	bottom: 0;
	transform: translate(-50%, 50%);
	-webkit-transform: translate(-50%, 50%);
	-moz-transform: translate(-50%, 50%);
	-ms-transform: translate(-50%, 50%);
	-o-transform: translate(-50%, 50%);
}

.menu-item_item__info__14kfM {
	display: grid;
	grid-template-areas: 'price slices' 'quantity quantity';
	align-items: center;
	align-content: center;
	grid-column-gap: 2rem;
	-webkit-column-gap: 2rem;
	        column-gap: 2rem;
	margin: 0 0 2rem;
	font-size: 2rem;
}

.menu-item_price__1juwT {
	grid-area: price;
}

.menu-item_slices__2ZUiX {
	grid-area: slices;
	display: flex;
	justify-content: space-evenly;
}

.custom-btn_custom__btn__1J0Bw {
  background: var(--red-orange-gradient);
  color: var(--white);
  padding: 1rem 3rem;
  border: none;
  font-size: 1.8rem;
  cursor: pointer;
  border-radius: 15rem;
  -webkit-border-radius: 15rem;
  -moz-border-radius: 15rem;
  -ms-border-radius: 15rem;
  -o-border-radius: 15rem;
}
.quantity-bar_quantity__2d6G4 {
	grid-area: quantity;
	justify-self: center;
	display: grid;
	justify-items: center;
	grid-template-columns: repeat(3, 1fr);
	grid-column-gap: 0.5rem;
	-webkit-column-gap: 0.5rem;
	        column-gap: 0.5rem;
}

.quantity-bar_quantity__2d6G4 span {
	font-size: 3rem;
	line-height: 1.5;
	width: 5rem;
	text-align: center;
}

.quantity-bar_quantity__btn__DC88O {
	border: none;
	background: transparent;
	color: currentColor;
	cursor: pointer;
	padding: 0.5rem;
}

.header_head__mq8nl {
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	width: 100%;
	height: 90vh;
	padding: 2rem 7rem;

	background: var(--light-black);
}

.header_head__text__3V9zk {
	width: 30%;
	height: 70%;
	display: grid;
	grid-row-gap: 2rem;
	row-gap: 2rem;
	align-items: center;
	align-content: flex-start;
	justify-items: start;
	padding: 0 2rem;
}

.header_title__3En5j {
	font-family: 'Fira Sans';
	font-weight: 700;
	font-size: var(--h1);
	text-shadow: 0.3rem 0.4rem 0.3rem var(--black);
}

.header_description__1bHYG {
	font-size: var(--small-text);
}

.header_head__img__1VOQA {
	width: 70%;
	height: 80vh;
}

@media (max-width: 1200px) {
	.header_head__mq8nl {
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		height: auto;
		padding: 5rem 7rem;
	}

	.header_head__text__3V9zk {
		margin: 5rem 0 0;
	}

	.header_head__text__3V9zk,
	.header_head__img__1VOQA {
		width: 100%;
		padding: 0 2.5rem;
	}
}

@media (max-width: 768px) {
	.header_head__mq8nl {
		padding: 2rem 4rem;
	}
}

@media (max-width: 640px) {
	.header_head__mq8nl {
		flex-direction: column;
	}

	.header_head__text__3V9zk,
	.header_head__img__1VOQA {
		padding: 0;
	}
}

.menu_menu__JT8So {
	text-align: center;
	display: grid;
	grid-row-gap: 5rem;
	row-gap: 5rem;
	justify-content: center;
	justify-items: center;
	margin: 4rem auto;
	padding: 10rem 4rem;
	background: var(--black);
}

.menu_title__3EF5K {
	font-size: var(--h2);
	font-weight: 700;
	text-shadow: 0.3rem 0.4rem 0.3rem var(--black);
}

.menu_items__2vZvX {
	display: grid;
	justify-content: start;
	grid-template-columns: repeat(3, 1fr);
	grid-row-gap: 15rem;
	row-gap: 15rem;
	grid-column-gap: 10rem;
	-webkit-column-gap: 10rem;
	        column-gap: 10rem;
	max-width: 80vw;
	margin: 5rem auto;
}

.menu_show__menu__1ibhc {
	border: 0.2rem solid var(--light-red-orange);
	background: transparent;
	padding: 1rem 3rem;
}

@media (max-width: 1640px) {
	.menu_items__2vZvX {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media (max-width: 1200px) {
	.menu_items__2vZvX {
		max-width: 100vw;
	}
}

@media (max-width: 768px) {
	.menu_items__2vZvX {
		grid-template-columns: 1fr;
	}
}

.about_about__2DIM6 {
	display: grid;
	place-items: center;
	grid-template-areas: 'about__text' 'about__img';
	overflow: hidden;
	padding: 2rem;
	margin: 5rem 0;
	filter: drop-shadow(0.2rem 0.2rem 0.4rem var(--black));
	-webkit-filter: drop-shadow(0.2rem 0.2rem 0.4rem var(--black));
}

.about_about__img__1d-Sn {
	grid-area: about__img;
	display: grid;

	width: 100vw;
	height: 60vh;
}

.about_about__img__1d-Sn img {
	justify-self: right;
	width: 130%;
	height: 100%;
}

.about_about__text__1ZevI {
	grid-area: about__text;
	justify-self: right;
	width: 90%;
	padding: 5rem 4rem;
	background: var(--black);
	border-radius: 0.8rem;
	-webkit-border-radius: 0.8rem;
	-moz-border-radius: 0.8rem;
	-ms-border-radius: 0.8rem;
	-o-border-radius: 0.8rem;
	filter: drop-shadow(0.2rem 0.2rem 0.8rem var(--black));
	-webkit-filter: drop-shadow(0.2rem 0.2rem 0.8rem var(--black));

	z-index: 1;
}

.about_title__1JNfb {
	font-size: var(--h2);
	line-height: 1.5;
}

.about_description__2lTA- {
	font-size: var(--small-text);
	line-height: 1.5;
}

@media (min-width: 768px) {
	.about_about__img__1d-Sn {
		height: 70vh;
	}
}

@media (min-width: 992px) {
	.about_about__2DIM6 {
		grid-template-areas: 'about__img . about__text';
		grid-template-columns: 1fr 2fr;
	}
	.about_about__img__1d-Sn {
		width: 70vw;
	}
	.about_about__img__1d-Sn img {
		width: 100%;
		height: 100%;
	}

	.about_about__text__1ZevI {
		width: 150%;
		justify-self: center;
		transform: translateX(-20%);
		-webkit-transform: translateX(-20%);
		-moz-transform: translateX(-20%);
		-ms-transform: translateX(-20%);
		-o-transform: translateX(-20%);
	}
}

@media (min-width: 1640px) {
	.about_about__text__1ZevI {
		width: 100%;
		transform: translateX(-40%);
		-webkit-transform: translateX(-40%);
		-moz-transform: translateX(-40%);
		-ms-transform: translateX(-40%);
		-o-transform: translateX(-40%);
	}
}

.contact_contact__18G_G {
	position: relative;
}

.contact_map__2qtJq {
	margin: 0 auto;
	width: 100%;
	border: none;
	filter: drop-shadow(0.2rem 0.2rem 0.5rem var(--light-black));
	-webkit-filter: drop-shadow(0.2rem 0.2rem 0.5rem var(--light-black));
}

.contact_contact__card__hwtkV {
	position: absolute;
	top: 50%;
	left: 50%;
	background: var(--black);
	opacity: 0.98;
	z-index: 1;
	width: 70vw;
	height: 60vh;
	max-width: 70rem;
	max-height: 55rem;

	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	border-radius: 1rem;
	-webkit-border-radius: 1rem;
	-moz-border-radius: 1rem;
	-ms-border-radius: 1rem;
	-o-border-radius: 1rem;
	filter: drop-shadow(0.2rem 0.2rem 0.4rem var(--black));
	-webkit-filter: drop-shadow(0.2rem 0.2rem 0.4rem var(--black));
	padding: 5rem;
	text-shadow: 0.2rem 0.4rem 0.4rem var(--black);
	overflow: hidden;
}

.contact_contact__card__hwtkV::after {
	content: '';
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 10%;
	left: 30%;
	background-image: url(/static/media/pizza_1.9fd8c35a.png);
	opacity: 0.4;
}

.contact_card__title__3wovy {
	font-size: var(--h2);
	line-height: 2;
}

.contact_title__3LcLS {
	padding: 0.5rem;
	font-size: var(--h4);
	line-height: 2;
}

.contact_description__1kQeJ {
	padding: 0.5rem 1.5rem 0.5rem 0.5rem;
	font-size: var(--tiny-text);
	line-height: 1.5;
	font-weight: 600;
}

@media (min-width: 1640px) {
	.contact_contact__card__hwtkV {
		width: 30vw;
		height: 50vh;
		transform: translate(-80%, -50%);
		-webkit-transform: translate(-80%, -50%);
		-moz-transform: translate(-80%, -50%);
		-ms-transform: translate(-80%, -50%);
		-o-transform: translate(-80%, -50%);
	}
}

@media (max-width: 640px) {
	.contact_contact__card__hwtkV {
		padding: 1rem 2rem;
	}

	.contact_map__2qtJq {
		width: 95%;
	}
}

.delivery_delivery__2eyhJ {
	padding: 5rem 2rem;
	display: grid;
	grid-template-areas: 'del__text del__img';
	justify-content: center;
	align-items: center;
	grid-template-columns: 1fr 1fr;
	justify-items: center;
	overflow: hidden;
	background: var(--black);
	filter: drop-shadow(0.2rem -0.2rem 0.4rem var(--black));
	-webkit-filter: drop-shadow(0.2rem -0.2rem 0.4rem var(--black));
}

.delivery_delivery__text__2V7Xd {
	grid-area: del__text;
}

.delivery_del__title__15WI7 {
	font-size: var(--h2);
	line-height: 2;
}

.delivery_title__3RRG1 {
	font-size: var(--h4);
	font-weight: 700;
	line-height: 1.5;
}

.delivery_description__1ffp7 {
	font-size: var(--small-text);
	line-height: 2;
}

.delivery_delivery__img__2WyfG {
	grid-area: del__img;
	width: 80%;
	height: 80%;
}

.delivery_delivery__img__2WyfG img {
	width: inherit;
}

@media (max-width: 640px) {
	.delivery_delivery__img__2WyfG {
		display: none;
	}

	.delivery_delivery__2eyhJ {
		grid-template-columns: 100%;
	}
}

.footer_footer__27N-i {
	color: var(--white);
	padding: 2rem;
	background: var(--light-black);
	filter: drop-shadow(0.2rem -0.1rem 0.4rem var(--black));
	-webkit-filter: drop-shadow(0.2rem -0.1rem 0.4rem var(--black));

	display: grid;
	grid-template-areas: 'links credits ' 'social credits ';
	grid-template-columns: repeat(2, 1fr);
	grid-row-gap: 3rem;
	row-gap: 3rem;
	text-align: center;
	justify-content: space-evenly;
	align-items: flex-start;
}

.footer_footer__links__3PHQj {
	grid-area: links;
	display: flex;
	justify-content: space-around;
	align-items: flex-start;
	font-size: var(--small-text);
}

.footer_social__links__ukJtc {
	grid-area: social;
	display: grid;
	grid-template: 100% / 0.1fr 0.1fr;
	grid-column-gap: 0.1rem;
	-webkit-column-gap: 0.1rem;
	        column-gap: 0.1rem;
	justify-content: center;
	justify-items: center;
	font-size: 4rem;
	color: var(--black);
}

.footer_link__rzOo0 {
	color: var(--white);
	cursor: pointer;
	line-height: 2;
}

.footer_credits__3DdQu {
	grid-area: credits;
	text-align: center;
	color: currentColor;
}

.footer_credits__title__3v9HR {
	font-size: var(--small-text);
	line-height: 1.5;
}

.footer_heart__3SuCG {
	margin: 1rem;
	color: var(--light-red-orange);
	font-size: var(--h3);
}

.footer_author__1uJN8 {
	font-size: var(--tiny-text);
	line-height: 1;
}

@media (max-width: 992px) {
	.footer_footer__27N-i {
		grid-template-areas: 'links . social' 'credits credits credits';
	}

	.footer_footer__links__3PHQj {
		flex-wrap: wrap;
	}

	.footer_social__links__ukJtc {
		grid-column-gap: 1.5rem;
		-webkit-column-gap: 1.5rem;
		        column-gap: 1.5rem;
	}
}

.Cart_cart__open__2hwHE {
	display: grid;
	grid-template-rows: 0.2fr 1.5fr 0.5fr;
	grid-template-areas: 'header' 'items' 'footer';
	justify-items: center;
	position: absolute;
	z-index: 2;
	right: 0;
	top: 80%;
	transform: translate(0%, 10%);
	min-width: 30vw;
	min-height: 30vh;
	padding: 2rem;
	overflow: visible;
	background: var(--light-black);

	/* AutoPrefixer ¯\_(ツ)_/¯ */
	filter: drop-shadow(0.6rem 0.4rem 0.5rem var(--black));
	-webkit-filter: drop-shadow(0.6rem 0.4rem 0.5rem var(--black));
	border-radius: 0.8rem;
	-webkit-border-radius: 0.8rem;
	-moz-border-radius: 0.8rem;
	-ms-border-radius: 0.8rem;
	-o-border-radius: 0.8rem;
	-webkit-animation: Cart_open__cart__1NfxV 0.5s ease;
	animation: Cart_open__cart__1NfxV 0.5s ease;
	transition: all 0.2s linear;
	-webkit-transition: all 0.2s linear;
	-moz-transition: all 0.2s linear;
	-ms-transition: all 0.2s linear;
	-o-transition: all 0.2s linear;
}

.Cart_cart__open__2hwHE::before {
	content: '';
	display: block;
	position: absolute;
	z-index: 2;
	top: -2%;
	right: 25%;
	border-left: 2rem solid transparent;
	border-right: 2rem solid transparent;
	border-bottom: 2rem solid var(--light-black);
	transform: translate(0%, -50%);
	-webkit-transform: translate(0%, -50%);
	-moz-transform: translate(0%, -50%);
	-ms-transform: translate(0%, -50%);
	-o-transform: translate(0%, -50%);
}

.Cart_cart__closed__1FYPN {
	display: none;
	height: 0;
}

.Cart_cart__empty__1LPMZ {
	text-align: center;
	width: 100%;
}

.Cart_cart__header__3I7k0 {
	grid-area: header;
}

.Cart_cart__items__2UJhR {
	grid-area: items;
	overflow: hidden scroll;
	max-height: 20vh;
	width: 100%;
}

.Cart_item__2O3WH {
	display: grid;
	grid-template-columns: 0.3fr 1fr 0.2fr;
	grid-template-areas: 'img text delete';
	align-items: flex-start;
	grid-column-gap: 2rem;
	-webkit-column-gap: 2rem;
	        column-gap: 2rem;
	margin: 2rem 4rem;
	padding: 2rem 0;
	border: none;
	border-bottom: 0.2rem solid var(--black);
}

.Cart_item__img__1Doy4 {
	grid-area: img;
	background: var(--black);
	border-radius: 0.5rem;
	width: 10rem;
	height: 10rem;
	display: grid;
	place-items: center;
}

.Cart_item__img__1Doy4 img {
	width: 80%;
	height: 80%;
}

.Cart_item__text__3W7U9 {
	grid-area: text;
	justify-self: flex-start;
}

.Cart_remove__btn__14sIo,
.Cart_close__btn__9Yzmt {
	color: currentColor;
	border: none;
	background: transparent;
	cursor: pointer;
	font-size: 2rem;
	place-self: center end;
}

.Cart_remove__btn__14sIo {
	grid-area: delete;
}

.Cart_name__1Pft0 {
	line-height: 1.5;
}

.Cart_cart__footer__1fcxJ {
	grid-area: footer;
	position: relative;
	justify-self: stretch;
}

.Cart_total__info__33HUu {
	justify-self: stretch;
	margin: 0 4rem;
	border-top: 0.2rem solid var(--pale-yellow);
	display: grid;
	font-size: var(--small-text);
	line-height: 4rem;
}

.Cart_cart__btns__2OluG {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.Cart_order__btn__2wf3Z {
	transform: translate(-50%, 100%);
	position: absolute;
	bottom: 0;
	left: 50%;
}

.Cart_close__btn__9Yzmt {
	font-size: 3rem;
	position: absolute;
	top: 5%;
	right: 5%;
}

.Cart_clear__btn__1AzHI {
	background: var(--pale-yellow);
	border: none;
	padding: 1rem 1.5rem;
	border-radius: 0.5rem;
	-webkit-border-radius: 0.5rem;
	-moz-border-radius: 0.5rem;
	-ms-border-radius: 0.5rem;
	-o-border-radius: 0.5rem;
}

@media (max-width: 1640px) {
	.Cart_cart__open__2hwHE {
		min-width: 40vw;
	}
}

@media (max-width: 1200px) {
	.Cart_cart__open__2hwHE {
		width: 70vw;
		height: 75vh;
		position: fixed;
		right: unset;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		-webkit-transform: translate(-50%, -50%);
		-moz-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		-o-transform: translate(-50%, -50%);
	}

	.Cart_cart__open__2hwHE::before {
		display: none;
	}

	.Cart_cart__items__2UJhR {
		max-height: 50vh;
	}
}

@media (max-width: 640px) {
	.Cart_cart__open__2hwHE {
		width: 100%;
		height: 100%;
	}

	.Cart_order__btn__2wf3Z {
		transform: none;
		-webkit-transform: none;
		-moz-transform: none;
		-ms-transform: none;
		-o-transform: none;

		top: unset;
		left: unset;
		position: unset;
	}

	.Cart_item__2O3WH {
		grid-template: 'img img' 1fr 'delete delete' 0.8fr 'text text' 0.5fr / 1fr;
		grid-row-gap: 0.2rem;
		row-gap: 0.2rem;
		position: relative;
	}

	.Cart_item__text__3W7U9 {
		text-align: center;
		justify-self: stretch;
	}

	.Cart_item__img__1Doy4 {
		justify-self: center;
	}

	.Cart_remove__btn__14sIo {
		justify-self: center;
		align-self: center;
		transform: scale(2);
		-webkit-transform: scale(2);
		-moz-transform: scale(2);
		-ms-transform: scale(2);
		-o-transform: scale(2);
	}
}

@-webkit-keyframes Cart_open__cart__1NfxV {
	0% {
		min-width: 0;
		min-height: 0;
		opacity: 0;
	}

	50% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes Cart_open__cart__1NfxV {
	0% {
		min-width: 0;
		min-height: 0;
		opacity: 0;
	}

	50% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.Checkout-form_checkout__form__1x0RP {
	margin: auto;
	width: 80%;
	display: grid;
	grid-template-areas: 'name' 'phone' 'address' 'payment' 'comments' 'action__btns';
	grid-template-columns: 100%;
	place-items: flex-start center;
	place-content: center;
	grid-column-gap: 5rem;
	-webkit-column-gap: 5rem;
	        column-gap: 5rem;
}

.Checkout-form_input__label__3fSoi {
	font-size: 2rem;
	margin: 2rem;
	width: 100%;
	filter: drop-shadow(0.1rem 0.1rem 0.2rem var(--light-black));
	-webkit-filter: drop-shadow(0.1rem 0.1rem 0.2rem var(--light-black));
}

.Checkout-form_input__field__1lau- {
	width: inherit;
	font-size: 2rem;
	line-height: 1.2;
	margin: 1rem 0.5rem;
	padding: 1.5rem 1rem;
	background: var(--light-black);
	color: currentColor;
	border: none;
	border-bottom: 0.4rem solid var(--pale-yellow);
	transition: all 0.3s ease-out;
	-webkit-transition: all 0.3s ease-out;
	-moz-transition: all 0.3s ease-out;
	-ms-transition: all 0.3s ease-out;
	-o-transition: all 0.3s ease-out;
	border-radius: 0.2rem;
	-webkit-border-radius: 0.2rem;
	-moz-border-radius: 0.2rem;
	-ms-border-radius: 0.2rem;
	-o-border-radius: 0.2rem;
}

.Checkout-form_input__field__1lau-:focus {
	background: var(--white);
	color: var(--black);
	border-color: var(--light-red-orange);
}

.Checkout-form_address__label__2qys3,
.Checkout-form_name__label__3wb_V,
.Checkout-form_phone__label__1Ba-5,
.Checkout-form_payment__label__2bkMH,
.Checkout-form_comments__label__oFaNE,
.Checkout-form_action__btns__3fKWy {
}

.Checkout-form_address__field__FwUTh,
.Checkout-form_name__field__zaRZE,
.Checkout-form_phone__field__y7jOP,
.Checkout-form_payment__field__3qlCu,
.Checkout-form_comments__field__1N9MU {
}

.Checkout-form_comments__field__1N9MU {
	height: 10rem;
	resize: none;
}

.Checkout-form_address__label__2qys3 {
	grid-area: address;
}

.Checkout-form_name__label__3wb_V {
	grid-area: name;
}

.Checkout-form_phone__label__1Ba-5 {
	grid-area: phone;
}

.Checkout-form_payment__label__2bkMH {
	grid-area: payment;
}

.Checkout-form_comments__label__oFaNE {
	grid-area: comments;
}

.Checkout-form_error__cq-__ {
	color: var(--dark-red-orange);
	font-size: 1.5rem;
}

.Checkout-form_action__btns__3fKWy {
	grid-area: action__btns;
	display: grid;
	grid-template-areas: 'remember__btn remember__btn' 'cancel__btn order__btn';
	grid-template-columns: 1fr 6fr;
	grid-template-rows: 1fr 1fr;
	justify-items: end;
}

.Checkout-form_remember__label__3TaCk {
	grid-area: remember__btn;
	cursor: pointer;
	display: grid;
	grid-template-columns: 1.5fr 0.5fr;
	grid-column-gap: 2rem;
	-webkit-column-gap: 2rem;
	        column-gap: 2rem;
}

.Checkout-form_remember__btn__52Qqh {
	width: 3rem;
	height: 3rem;
	cursor: inherit;
}

.Checkout-form_cancel__btn__3hRZ2 {
	color: var(--black);
	grid-area: cancel__btn;
}

.Checkout-form_order__btn__o2lU3 {
	grid-area: order__btn;
}

@media (min-width: 992px) {
	.Checkout-form_checkout__form__1x0RP {
		grid-template-areas: 'name phone' 'address address' 'payment comments' 'action__btns action__btns';
		grid-template-columns: auto;
	}

	.Checkout-form_order__btn__o2lU3 {
		/* position: absolute;
		bottom: -7.5%;
		left: 50%;
		transform: translate(-50%, -91.5%); */
	}
}

.Checkout_checkout__open__2aQm5 {
	overflow: scroll;
	padding: 0 0 5rem;
	display: block;
	position: fixed;
	z-index: 4;
	top: 50%;
	left: 50%;
	width: 100%;
	height: 100%;
	background: var(--black);
	max-width: 150rem;
	color: var(--white);
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	border-radius: 0.8rem;
	-webkit-border-radius: 0.8rem;
	-moz-border-radius: 0.8rem;
	-ms-border-radius: 0.8rem;
	-o-border-radius: 0.8rem;
	filter: drop-shadow(0.1rem 0.1rem 0.5rem var(--black));
	-webkit-filter: drop-shadow(0.1rem 0.1rem 0.5rem var(--black));
	box-shadow: 0 0 0.1rem 0.5rem var(--light-grey);
}

.Checkout_checkout__closed__3mEG- {
	display: none;
}

.Checkout_checkout__header__1fM5U {
	text-align: center;
	display: grid;
	align-content: baseline;
	padding: 6rem 0;
}

.Checkout_title__2KC_h {
	font-size: var(--h3);
}

.Checkout_close__btn__1EIhD {
	color: currentColor;
	border: none;
	background: transparent;
	cursor: pointer;
	font-size: 3rem;
	place-self: center end;
	right: 5%;
	position: relative;
}

@media (min-width: 992px) {
	.Checkout_checkout__open__2aQm5 {
		width: 50%;
		height: 90%;
		overflow: unset;
		padding: unset;
	}

	.Checkout_checkout__header__1fM5U {
		padding: 1rem 0;
	}
}

@media (min-width: 1640px) {
	.Checkout_checkout__header__1fM5U {
		padding: 4rem 0;
	}
}

.Order_order__page__2MWh_ {
	width: 80vw;
	height: 80vh;
	display: grid;
	grid-row-gap: 2rem;
	row-gap: 2rem;
	grid-template-areas: 'del__info' 'orders' 'go__back';
	grid-template-rows: 0.2fr 3fr 0.5fr;
	justify-content: center;
	place-items: center;
	padding: 1rem;
}

.Order_del__info__nx-8z {
	grid-area: del__info;
	font-size: var(--h3);

	display: grid;
	grid-row-gap: 0.5rem;
	row-gap: 0.5rem;
	padding: 2rem;

	justify-content: center;
}

.Order_orders__2oX-q {
	grid-area: orders;
	display: grid;
	grid-template: 1fr / 1fr;
	grid-row-gap: 2rem;
	row-gap: 2rem;
	height: 100%;
	overflow-y: scroll;
}

.Order_order__item__2B05F {
	background: var(--light-black);
	padding: 2rem;
	filter: drop-shadow(0.2rem 0.2rem 0.4rem var(--black));
	-webkit-filter: drop-shadow(0.2rem 0.2rem 0.4rem var(--black));
	border-radius: 0.8rem;
	-webkit-border-radius: 0.8rem;
	-moz-border-radius: 0.8rem;
	-ms-border-radius: 0.8rem;
	-o-border-radius: 0.8rem;
}

.Order_order__img__ZuHcx {

	width: 20rem;
	height: 20rem;
}

.Order_order__text__1xLxM {
	font-size: var(--h4);
}

.Order_name__j8QZN {
	font-size: var(--h3);
}

.Order_back__btn__36AYY {
	display: grid;
	place-items: center;
	justify-self: start;
	background: transparent;
	color: var(--light-grey);
	border: 0.2rem solid var(--light-grey);
	font-size: var(--h2);
	cursor: pointer;
	margin: 0.8rem 3rem;
	padding: 0.5rem;
	width: 5rem;
	height: 5rem;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
}

.Order_back__btn__36AYY * {
	width: 100%;
	height: 100%;
}

@media (max-width: 640px) {
	.Order_order__item__2B05F {
		grid-template:
			'img img' 0.5fr
			'text text' 0.5fr / 1fr;

		grid-row-gap: 0.5rem;

		row-gap: 0.5rem;
	}
}

@media (min-width: 1640px) {
	.Order_orders__2oX-q {
		grid-template: 1fr 1fr / 1fr 1fr;
	}
}

