.cart__open {
	display: grid;
	grid-template-rows: 0.2fr 1.5fr 0.5fr;
	grid-template-areas: 'header' 'items' 'footer';
	justify-items: center;
	position: absolute;
	z-index: 2;
	right: 0;
	top: 80%;
	transform: translate(0%, 10%);
	min-width: 30vw;
	min-height: 30vh;
	padding: 2rem;
	overflow: visible;
	background: var(--light-black);

	/* AutoPrefixer ¯\_(ツ)_/¯ */
	filter: drop-shadow(0.6rem 0.4rem 0.5rem var(--black));
	-webkit-filter: drop-shadow(0.6rem 0.4rem 0.5rem var(--black));
	border-radius: 0.8rem;
	-webkit-border-radius: 0.8rem;
	-moz-border-radius: 0.8rem;
	-ms-border-radius: 0.8rem;
	-o-border-radius: 0.8rem;
	-webkit-animation: open__cart 0.5s ease;
	animation: open__cart 0.5s ease;
	transition: all 0.2s linear;
	-webkit-transition: all 0.2s linear;
	-moz-transition: all 0.2s linear;
	-ms-transition: all 0.2s linear;
	-o-transition: all 0.2s linear;
}

.cart__open::before {
	content: '';
	display: block;
	position: absolute;
	z-index: 2;
	top: -2%;
	right: 25%;
	border-left: 2rem solid transparent;
	border-right: 2rem solid transparent;
	border-bottom: 2rem solid var(--light-black);
	transform: translate(0%, -50%);
	-webkit-transform: translate(0%, -50%);
	-moz-transform: translate(0%, -50%);
	-ms-transform: translate(0%, -50%);
	-o-transform: translate(0%, -50%);
}

.cart__closed {
	display: none;
	height: 0;
}

.cart__empty {
	text-align: center;
	width: 100%;
}

.cart__header {
	grid-area: header;
}

.cart__items {
	grid-area: items;
	overflow: hidden scroll;
	max-height: 20vh;
	width: 100%;
}

.item {
	display: grid;
	grid-template-columns: 0.3fr 1fr 0.2fr;
	grid-template-areas: 'img text delete';
	align-items: flex-start;
	column-gap: 2rem;
	margin: 2rem 4rem;
	padding: 2rem 0;
	border: none;
	border-bottom: 0.2rem solid var(--black);
}

.item__img {
	grid-area: img;
	background: var(--black);
	border-radius: 0.5rem;
	width: 10rem;
	height: 10rem;
	display: grid;
	place-items: center;
}

.item__img img {
	width: 80%;
	height: 80%;
}

.item__text {
	grid-area: text;
	justify-self: flex-start;
}

.remove__btn,
.close__btn {
	color: currentColor;
	border: none;
	background: transparent;
	cursor: pointer;
	font-size: 2rem;
	place-self: center end;
}

.remove__btn {
	grid-area: delete;
}

.name {
	line-height: 1.5;
}

.cart__footer {
	grid-area: footer;
	position: relative;
	justify-self: stretch;
}

.total__info {
	justify-self: stretch;
	margin: 0 4rem;
	border-top: 0.2rem solid var(--pale-yellow);
	display: grid;
	font-size: var(--small-text);
	line-height: 4rem;
}

.cart__btns {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.order__btn {
	transform: translate(-50%, 100%);
	position: absolute;
	bottom: 0;
	left: 50%;
}

.close__btn {
	font-size: 3rem;
	position: absolute;
	top: 5%;
	right: 5%;
}

.clear__btn {
	background: var(--pale-yellow);
	border: none;
	padding: 1rem 1.5rem;
	border-radius: 0.5rem;
	-webkit-border-radius: 0.5rem;
	-moz-border-radius: 0.5rem;
	-ms-border-radius: 0.5rem;
	-o-border-radius: 0.5rem;
}

@media (max-width: 1640px) {
	.cart__open {
		min-width: 40vw;
	}
}

@media (max-width: 1200px) {
	.cart__open {
		width: 70vw;
		height: 75vh;
		position: fixed;
		right: unset;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		-webkit-transform: translate(-50%, -50%);
		-moz-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		-o-transform: translate(-50%, -50%);
	}

	.cart__open::before {
		display: none;
	}

	.cart__items {
		max-height: 50vh;
	}
}

@media (max-width: 640px) {
	.cart__open {
		width: 100%;
		height: 100%;
	}

	.order__btn {
		transform: none;
		-webkit-transform: none;
		-moz-transform: none;
		-ms-transform: none;
		-o-transform: none;

		top: unset;
		left: unset;
		position: unset;
	}

	.item {
		grid-template: 'img img' 1fr 'delete delete' 0.8fr 'text text' 0.5fr / 1fr;
		row-gap: 0.2rem;
		position: relative;
	}

	.item__text {
		text-align: center;
		justify-self: stretch;
	}

	.item__img {
		justify-self: center;
	}

	.remove__btn {
		justify-self: center;
		align-self: center;
		transform: scale(2);
		-webkit-transform: scale(2);
		-moz-transform: scale(2);
		-ms-transform: scale(2);
		-o-transform: scale(2);
	}
}

@keyframes open__cart {
	0% {
		min-width: 0;
		min-height: 0;
		opacity: 0;
	}

	50% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
