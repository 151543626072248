.menu {
	text-align: center;
	display: grid;
	row-gap: 5rem;
	justify-content: center;
	justify-items: center;
	margin: 4rem auto;
	padding: 10rem 4rem;
	background: var(--black);
}

.title {
	font-size: var(--h2);
	font-weight: 700;
	text-shadow: 0.3rem 0.4rem 0.3rem var(--black);
}

.items {
	display: grid;
	justify-content: start;
	grid-template-columns: repeat(3, 1fr);
	row-gap: 15rem;
	column-gap: 10rem;
	max-width: 80vw;
	margin: 5rem auto;
}

.show__menu {
	border: 0.2rem solid var(--light-red-orange);
	background: transparent;
	padding: 1rem 3rem;
}

@media (max-width: 1640px) {
	.items {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media (max-width: 1200px) {
	.items {
		max-width: 100vw;
	}
}

@media (max-width: 768px) {
	.items {
		grid-template-columns: 1fr;
	}
}
