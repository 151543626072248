a {
	text-decoration: none;
}

.header {
	display: grid;
	align-items: center;
	justify-content: space-between;
	grid-template-columns: 0.5fr 1fr 0.2fr;
	grid-template-areas: 'logo links cart';
	line-height: 9rem;

	background: var(--light-black);
	font-size: var(--small-text);
	padding: 2rem 7rem;
}

.header__logo {
	grid-area: logo;
	font-weight: 700;
	font-size: 3.5rem;
	color: var(--white);
	display: flex;
	align-items: center;
}

.header__logo img {
	width: 10rem;
	transform: scale(0.7);
	-webkit-transform: scale(0.7);
	-moz-transform: scale(0.7);
	-ms-transform: scale(0.7);
	-o-transform: scale(0.7);
}

.header__links {
	grid-area: links;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	width: 100%;
}

.link {
	color: var(--white);
	filter: drop-shadow(0.5rem 0.5rem 0.5rem var(--black));
	-webkit-filter: drop-shadow(0.5rem 0.5rem 0.5rem var(--black));
	position: relative;
	cursor: pointer;
}

.link::after {
	content: '';
	display: block;
	position: absolute;
	bottom: 25%;
	left: 51%;
	right: 51%;
	height: 0.2rem;
	background: currentColor;
	transition: all 0.3s 0.09s ease;
	-webkit-transition: all 0.3s 0.09s ease;
	-moz-transition: all 0.3s 0.09s ease;
	-ms-transition: all 0.3s 0.09s ease;
	-o-transition: all 0.3s 0.09s ease;
}

.link:hover::after {
	left: 0;
	right: 0;
}

.header__cart {
	grid-area: cart;
	position: relative;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.nav__hamburger {
	display: none;
	place-items: center;
	border: none;
	background: transparent;
	font-size: 3rem;
	color: currentColor;
	cursor: pointer;
}

.cart__btn {
	display: grid;
	place-items: center;
	position: relative;
	padding: 2rem;
	cursor: pointer;
	border: none;
	background: transparent;
	color: var(--light-red-orange);
	filter: drop-shadow(0.5rem 0.5rem 0.5rem var(--black));
	-webkit-filter: drop-shadow(0.5rem 0.5rem 0.5rem var(--black));
	font-size: 4rem;
}

.cart__counter {
	display: grid;
	place-items: center;
	position: absolute;
	bottom: 15%;
	right: 15%;
	width: 2.5rem;
	height: 2.5rem;
	background: var(--white);
	color: currentColor;
	font-size: 2rem;
	font-weight: 600;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
}

@media (max-width: 1200px) {
	.header {
		grid-template-columns: 0.5fr 1fr;
		grid-template-areas: 'logo cart';
	}

	.header__links {
		display: none;
	}

	.mobile__links {
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		align-items: center;
		margin: 0 auto;
		z-index: 3;
		background: var(--black);
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transition: all 0.2s linear;
		-webkit-transition: all 0.2s linear;
		-moz-transition: all 0.2s linear;
		-ms-transition: all 0.2s linear;
		-o-transition: all 0.2s linear;
		animation: toggle__menu 0.4s linear;
		-webkit-animation: toggle__menu 0.4s linear;
	}

	.header__cart {
		justify-content: flex-end;
		align-items: baseline;
	}

	.nav__hamburger {
		display: grid;
	}

	.nav__close {
		display: grid;
		position: absolute;
		top: 5%;
		right: 5%;
	}
}

@media (max-width: 768px) {
	.header {
		padding: 2rem;
	}

	.header__logo span {
		display: none;
	}
}

@keyframes toggle__menu {
	0% {
		opacity: 0;
		top: 0;
	}

	50% {
		opacity: 0;
		top: 100%;
	}

	100% {
		opacity: 1;
		width: 100%;
		height: 100%;
	}
}
